import { Image } from "@nextui-org/react";
import preview1 from "../assets/images/preview1.png";
import preview2 from "../assets/images/preview2.png";
import preview3 from "../assets/images/preview3.png";
import preview4 from "../assets/images/preview4.png";
import PlayStoreImage from "../assets/images/GetItOnGooglePlay.png";
import AppstoreImage from "../assets/images/DownloadOnTheAppStore.svg";
import {useEffect, useRef, useState} from "react";

export default function Home() {
    const images = [preview1, preview2, preview3, preview4];
    const [currentIndex, setCurrentIndex] = useState(0);
    const intervalRef = useRef(null);

    // Function to start the image change interval
    const startInterval = () => {
        intervalRef.current = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5000);
    };

    // Change the image every 5 seconds
    useEffect(() => {
        startInterval();

        return () => clearInterval(intervalRef.current); // Cleanup the interval on component unmount
    }, []);

    const handleImageClick = (index) => {
        setCurrentIndex(index);

        // Clear the existing interval and start a new one
        clearInterval(intervalRef.current);
        startInterval();
    };

    return (
        <div className="relative isolate px-6 mt-20 md:mt-28 lg:px-8 overflow-x-hidden">
            <div className="text-center md:text-left mx-auto max-w-[1024px] relative flex">
                <div className="md:w-[60%] md:flex-shrink-0">
                    <h1 className="text-4xl font-bold tracking-tight text-black sm:text-6xl">
                        Compete in Challenges With Your Friends
                    </h1>
                    <p className="mt-2 md:mt-6 text-lg leading-8 text-gray-600">
                        Create challenges, dare your friends & earn points by competing
                    </p>
                    <div className="mt-8 flex items-center justify-center md:justify-start gap-x-6">
                        <a href={process.env.REACT_APP_GOOGLE_PLAY_URL}>
                            <Image src={PlayStoreImage} alt="Play Store" className="h-14 rounded-none" />
                        </a>
                        <a href={process.env.REACT_APP_APPLE_STORE_URL}>
                            <Image src={AppstoreImage} alt="App Store" className="h-14 rounded-none" />
                        </a>
                    </div>
                </div>

                <div className="hidden md:flex md:w-[40%] pt-6 space-x-4 flex-shrink-0 relative">
                    {images.map((image, index) => (
                        <div
                            key={index}
                            className={`absolute right-0 transition-all duration-500 ease-in-out ${
                                index === currentIndex
                                    ? "transform scale-110 z-10"
                                    : index === (currentIndex + 1) % images.length
                                        ? "transform translate-x-1/4 opacity-75 cursor-pointer"
                                        : index === (currentIndex + 2) % images.length
                                            ? "transform -translate-x-1/4 opacity-50 cursor-pointer"
                                            : "transform -translate-x-full opacity-0 pointer-events-none"
                            }`}
                            onClick={() => handleImageClick(index)}
                        >
                            <Image src={image} alt={`Preview ${index + 1}`} className="h-96" />
                        </div>
                    ))}
                </div>
            </div>

            <div className="mt-28 sm:mt-14 md:mt-56">
                <script type="text/javascript" src="https://campaigns.zoho.eu/js/zc.iframe.js"></script>
                <iframe
                    frameBorder="0"
                    id="iframewin"
                    width="100%"
                    height="100%"
                    src="https://zcv2-zcmp.maillist-manage.eu/ua/Optin?od=12ba7efa8d81&zx=14ae2a32bf&tD=134391e0be03f159&sD=134391e0be03f293"
                ></iframe>
            </div>
        </div>
    );
}
